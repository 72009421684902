<script>
export default {
  name: 'OemApiTable',
  props: {
    action: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    addModal: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      query: '',
      count: null,
      items: [],
      loading: true,
    };
  },
  watch: {
    page() {
      this.refresh();
    },
  },
  methods: {
    /**
     * Can be called externally. Refreshes the data in the table
     */
    refresh() {
      this.loading = true;
      this.$store.dispatch(this.action, {
        query: this.query.length > 0 ? this.query : undefined,
        limit: this.itemsPerPage,
        offset: this.itemsPerPage * (this.page - 1),
      })
        .then((resp) => {
          this.count = resp.count;
          this.items = resp.results;
          this.loading = false;
        });
    },
    /**
     * Emit an event to the parent with the userid of the
     *   user to be edited
     */
    editSelected(id) {
      const value = this.items.find(u => u.id === id);
      this.$emit('input', value);
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
<template>
  <b-card style='width: 100%'>

    <!-- Header -->
    <template v-slot:header>
      <b-container fluid>
        <b-row no-gutters style='width: 100%'>
          <!-- Search Bar -->
          <b-col cols=10 md=6 lg=5>
            <b-input-group>
              <b-input placeholder='Search' v-model='query' v-on:keyup.enter='refresh'></b-input>
              <b-input-group-append>
                <b-btn variant='success' @click='refresh'>Go</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols=1 offset=1 offset-md=5 offset-lg=6>
            <b-btn v-if='addModal'
                   @click='$bvModal.show(addModal)'
                   variant='success'
                   class='float-right'
                   ><fa-icon icon='plus'></fa-icon>
            </b-btn>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <!-- Table Main Body -->
    <b-container>
      <b-table id='oem-user-table'
               v-bind:items='items'
               :fields='fields'
               v-bind:busy='loading'
               responsive>

        <!-- Loading spinner -->
        <template v-slot:table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

        <!-- Last Column with Edit Button -->
        <template v-slot:cell(id)="data">
          <b-btn variant='secondary' @click='editSelected(data.value)'>
            <fa-icon icon='edit'></fa-icon>
          </b-btn>
        </template>

      </b-table>
    </b-container>

    <!-- Footer -->
    <template v-slot:footer>
      <b-container fluid>
        <b-row align-h='center' no-gutters>
          <b-col cols='auto'>
            <b-pagination
              align="center"
              v-model="page"
              :total-rows="count"
              :per-page="itemsPerPage"
              aria-controls="oem-user-table"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </template>

  </b-card>
</template>
